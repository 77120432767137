function AppFooterCopyright({ data }) {
    const currentYear = new Date().getFullYear();
    
    return (
        <div className="font-general-regular flex justify-center items-center text-center pb-5">
            <div className="text-lg text-ternary-dark dark:text-ternary-light">
				Copyright &copy; {currentYear}-{currentYear+1} - {data?.first_name} {data?.last_name}. All Rights Reserved.
            </div>
        </div>
    );
}

export default AppFooterCopyright;

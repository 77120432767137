import { AnimatePresence } from 'framer-motion';
import { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import AppFooter from './components/common/AppFooter';
import AppHeader from './components/common/AppHeader';
import UseScrollToTop from './hooks/useScrollToTop';
import CustomCursor from './components/CustomCursor';
import ChatIcon from './components/chatbot/ChatIcon';
import { ThemeProvider } from './hooks/useThemeSwitcher';
import './css/App.css';
import './css/ChatBot.css';

const About = lazy(() => import('./pages/AboutMe.jsx'));
const Contact = lazy(() => import('./pages/Contact.jsx'));
const Home = lazy(() => import('./pages/Home.jsx'));
const Projects = lazy(() => import('./pages/Projects.jsx'));
const ProjectDetails = lazy(() => import('./pages/ProjectDetails.jsx'));
const ChatPage = lazy(() => import('./components/chatbot/ChatPage.jsx'));

function DarkLightBlueTheme() {
  return (
    <ThemeProvider>
      <AnimatePresence>
        <div className='sm:flex-col bg-secondary-light dark:bg-primary-dark transition duration-300 sm:min-h-screen'>
          <Router>
            <ScrollToTop />
            <AppHeader />
            <Suspense fallback={''}>
              <Routes>
                <Route path={`/`} element={<Home />} />
                <Route path={`/about`} element={<About />} />
                <Route path={`/portfolio`} element={<Projects />} />
                <Route
                  path={`/portfolio/project-detail`}
                  element={<ProjectDetails />}
                />
                <Route path={`/contact`} element={<Contact />} />
                <Route path={'/Ask-me'} element={<ChatPage />} />
              </Routes>
            </Suspense>
            {/* <ChatIcon /> */}
            <AppFooter />
            <UseScrollToTop />
            <CustomCursor />
          </Router>
        </div>
      </AnimatePresence>
    </ThemeProvider>
  );
}

export default DarkLightBlueTheme;

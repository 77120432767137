import {
	FiGithub,
	FiTwitter,
	FiLinkedin,
	FiGlobe
} from 'react-icons/fi';
import { useContext } from 'react';
import AppFooterCopyright from './AppFooterCopyright';
import { DataStateContext } from '../../../context/ProfileDataContext';

const AppFooter = () => {

	const { data } = useContext(DataStateContext);

	const socialLinks = [
		{
			id: 1,
			icon: <FiGlobe />,
			url: data?.blog,
		},
		{
			id: 2,
			icon: <FiGithub />,
			url: data?.github,
		},
		{
			id: 3,
			icon: <FiTwitter />,
			url: data?.twitter,
		},
		{
			id: 4,
			icon: <FiLinkedin />,
			url: data?.linkdin,
		}
	];

	return (	
		<div className="container mx-auto">
			<div className="border-t-2 border-primary-light dark:border-secondary-dark">
				{/* Footer social links */}
				<div className="py-20 font-general-regular flex flex-col justify-center items-center">
					<p className="text-3xl sm:text-4xl text-primary-dark dark:text-primary-light mb-5">
						Follow me
					</p>
					<ul className="flex gap-4 sm:gap-8">
						{socialLinks.map((link) => (
							<a
								href={link.url}
								target="__blank"
								key={link.id}
								className="text-gray-400 hover:text-indigo-500 dark:hover:text-indigo-400 cursor-pointer rounded-lg bg-gray-50 dark:bg-ternary-dark hover:bg-gray-100 shadow-sm p-4 duration-300"
							>
								<i className="text-xl sm:text-2xl md:text-3xl">
									{link.icon}
								</i>
							</a>
						))}
					</ul>
				</div>

				<AppFooterCopyright data={data} />
			</div>
		</div>
	);
};

export default AppFooter;

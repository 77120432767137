import { useContext } from 'react';
import DarkLightBlueTheme from './dark-light-blue-theme/App';
import Template2 from './template2/App';
import { DataStateContext } from './context/ProfileDataContext';

function TemplateSwitcher() {
  const { data } = useContext(DataStateContext);

  let templateComponent;

  switch (data.template) {
    case 'dark-light-blue-theme':
      templateComponent = <DarkLightBlueTheme />;
      break;
    case 'template2':
      templateComponent = <Template2 />;
      break;

    default:
      templateComponent = <DarkLightBlueTheme />;
      break;
  }

  return <>{templateComponent}</>;
}

export default TemplateSwitcher;

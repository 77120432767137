import React from 'react';
import ReactDOM from 'react-dom/client';
import './dark-light-blue-theme/css/tailwind.css';
import TemplateSwitcher from './TemplateSwitcher';
import reportWebVitals from './reportWebVitals';
import { DataStateProvider } from './context/ProfileDataContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <DataStateProvider>
      <TemplateSwitcher />
    </DataStateProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
